import React from 'react'

const NotFoundRoute = () => {
  return (
    <>
    

<div style={{height:'30vh', display:'flex', justifyContent:'center',alignItems:'center',fontSize: "30px",
        color: "red",
        fontWeight: "bold", flexDirection:'column'}}>
        <div>{":("}</div>
        <div>Sorry Route not found</div>
        </div>
    </>
   
  )
}

export default NotFoundRoute